angular.module('daysend', [
    'ui.bootstrap',
    'ngRoute',
    'ngAnimate',
    'angularjs-dropdown-multiselect'
])

.directive('fileChange', function() {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var onChangeHandler = scope.$eval(attrs.fileChange);
            element.bind('change', onChangeHandler);
        }
    };
})

.config(function($httpProvider, $locationProvider, $routeProvider, $qProvider, $sceProvider) {
    $httpProvider.defaults.transformRequest = function(data) {
        if (data === undefined) { return data; }
        return $.param(data);
    };
    $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
    $locationProvider.hashPrefix('');

    // Fix/prevent obfuscating injection errors
    $qProvider.errorOnUnhandledRejections(false);

    // Fixes escaping issues in the results.
    // Safe for use on admin only pages.
    $sceProvider.enabled(false);

    $routeProvider
        // region public
        .when('/', {
            templateUrl: 'modules/app/templates/public/home.html'
        })
        .when('/delivery', {
            templateUrl: 'modules/app/templates/public/delivery.html'
        })
        .when('/order', {
            templateUrl: 'modules/app/templates/public/order.html',
            controller: 'orderPageController',
            resolve: {
                $promoType: function() { return ''; }
            }
        })
        .when('/free3', {
            templateUrl: 'modules/app/templates/public/order_free3.html',
            controller: 'orderPageController',
            resolve: {
                $promoType: function() { return 'free3'; }
            }
        })
        .when('/payment', {
            templateUrl: 'modules/app/templates/public/payment.html'
        })
        .when('/question', {
            templateUrl: 'modules/app/templates/public/question.html'
        })
        .when('/sample', {
            templateUrl: 'modules/app/templates/public/sample.html'
        })
        .when('/update', {
            templateUrl: 'modules/app/templates/public/update.html'
        })
        .when('/map', {
            templateUrl: 'modules/app/templates/public/map.html',
            controller: 'mainMapController'
        })
        .when('/register', {
            templateUrl: 'modules/app/templates/public/register.html'
        })
        .when('/forgot-password', {
            templateUrl: 'modules/app/templates/public/forgot-password.html'
        })
        .when('/photo-submit-how-to', {
            templateUrl: 'modules/app/templates/public/photo-submit-how-to.html'
        })
        .when('/direct-registration', {
            templateUrl: 'modules/app/templates/public/direct-registration.html'
        })
        // endregion public

        // region subscribed
        .when('/about-download-versions', {
            templateUrl: 'modules/app/templates/subscribed/download_about.php'
        })
        .when('/directory-notes', {
            templateUrl: 'modules/app/templates/subscribed/directory-notes.php'
        })
        .when('/state-province-notes', {
            templateUrl: 'modules/app/templates/subscribed/state-province-notes.php'
        })
        .when('/good-guys', {
            templateUrl: 'modules/app/templates/subscribed/good-guys.php'
        })
        .when('/faq', {
            templateUrl: 'modules/app/templates/subscribed/faq.php'
        })
        .when('/reporting-rewards', {
            templateUrl: 'modules/app/templates/subscribed/reporting-rewards.php'
        })
        .when('/addition-report-form', {
            templateUrl: 'modules/app/templates/subscribed/addition-report-form.php'
        })
        .when('/update-report-form', {
            templateUrl: 'modules/app/templates/subscribed/update-report-form.php',
            controller: 'updateReportController',
            resolve: {
                $listingID: function() { return ''; },
                $listingDesc: function() { return ''; }
            }
        })
        .when('/change-password', {
            templateUrl: 'modules/app/templates/subscribed/change-password.php'
        })
        .when('/change-email', {
            templateUrl: 'modules/app/templates/subscribed/change-email.php'
        })
        .when('/directory-search', {
            templateUrl: 'modules/app/templates/subscribed/directory-search.php'
        })
        .when('/photos', {
            templateUrl: 'modules/app/templates/subscribed/photos.php'
        })
        .when('/files', {
            templateUrl: 'modules/app/templates/subscribed/files.php'
        })
        .when('/updates-log', {
            templateUrl: 'modules/app/templates/subscribed/updates-log.php'
        })
        .when('/subscriber-blogs', {
            templateUrl: 'modules/app/templates/subscribed/subscriber-blogs.php'
        })
        .when('/skp-memorabilia', {
            templateUrl: 'modules/app/templates/subscribed/skp-memorabilia.php'
        })
        // endregion subscribed

        // region admin
        .when('/activateuser', {
            templateUrl: 'modules/app/templates/admin/activateuser.php'
        })
        .when('/admin-send-mail', {
            templateUrl: 'modules/app/templates/admin/send-mail.php'
        })
        .when('/admin-inquiries', {
            templateUrl: 'modules/app/templates/admin/inquiries.php'
        })
        .when('/admin-expiries', {
            templateUrl: 'modules/app/templates/admin/expiries.php'
        })
        .when('/admin-add-to-directory', {
            templateUrl: 'modules/app/templates/admin/add-to-directory.php'
        })
        // endregion admin

        .otherwise({
            templateUrl: '404/template.php'
        });
})
.run(function _forceAutofocus($rootScope, $timeout) {
    // Source: https://rommelsantor.com/clog/2015/10/14/angularjs-auto-focus-input-after-router-template-is-loaded-in-spa/
    // tell Angular to call this function when a route change completes
    $rootScope.$on('$routeChangeSuccess', function() {
        // we can't set focus at this point; the DOM isn't ready for us

        // instead, we define a callback to be called after the $digest loop
        $timeout(function(){
            // once this is executed, our input should be focusable, so find (with jQuery)
            // whatever is on the page with the autofocus attribute and focus it; fin.
            $('[autofocus]').focus();
        });
    });

});
angular.module('daysend').controller('activateUserController', function($scope, $http, $location, $routeParams){
    // For activating a user by URI
    if (!$.isEmpty($routeParams.fid)) {
        var loadMask = $('html').loadMask();
        $scope.activateResult = 'Activating, please wait...';
        $scope.activateByURI = true;
        $scope.activateByURISuccess = false;

        var params = {
            service: 'activate_user_by_link',
            fid: $routeParams.fid
        };

        $http({
            method: 'POST',
            url: 'service/RegistrationService.php',
            data: params
        }).then(function(response) {
            loadMask.fadeOut(500);
            $scope.activateResult = response.data.msg;
            $scope.activateByURISuccess = true;
        }, function(response){
            loadMask.fadeOut(500);
        });
    }

    // For entering a specific username out of context
    $scope.onSubmit = function(e) {
        var loadMask = $('html').loadMask();
        $scope.showResultData = false;
        var params = $(e.target).serializeObject();

        switch (params.action) {
            case 'check_user_status':
                params.service = 'get_user_status';
                break;
            case 'activate':
                params.service = 'activate_user_by_email';
                break;
            case 'deactivate':
                params.service = 'activate_user_by_email';
                break;
            case 'get_info':
                params.service = 'get_order_info';
                break;
            case 'remove_user':
                params.service = 'remove_user_by_email';
                break;
            default:
        }

        $http({
            method: 'POST',
            url: 'service/RegistrationService.php',
            data: params
        }).then(function(response) {
            loadMask.fadeOut(500);
            $scope.showResultData = true;
            $scope.resultData = response.data.msg;
        }, function(response){
            loadMask.fadeOut(500);
        });
    }
});
angular.module('daysend').controller('additionReportController', function($scope, $http, $filter){
    $scope.additionComplete = false;
    $scope.settings = {};
    $scope.params = {};
    $scope.settings.old_time_stay_limit = $scope.params.time_stay_limit;
    $scope.settings.old_fees = $scope.params.fees;

    $scope.showDatePick = false;
    $scope.datePickVal = null;
    $scope.datePickOptions = {
        datepickerMode: 'month',
        minMode: 'month',
        maxMode: 'month',
        showWeeks: true,
        formatMonth: 'MMM',
        formatYear: 'yy',
        formatDay: ''
    };

    $scope.onDateChange = function() {
        $scope.params['month_year_visited'] = $filter('date')($scope.datePickVal, 'MMM yy');
    };

    $scope.toggleDatePick = function() {
        $scope.showDatePick = !$scope.showDatePick;
    };

    $scope.onSubmit = function(e, additionReportForm) {
        if (!additionReportForm.$valid) {
            $('html').loadMask('The report is not valid, please ensure all required fields are completed before submitting.', false)
                .fadeOut(4000);
            return false;
        }

        var loadMask = $('html').loadMask();
        $scope.params.service = 'addition_report';

        $http({
            method: 'POST',
            url: 'service/DirectoryService.php',
            data: $scope.params
        }).then(function(response) {
            loadMask.fadeOut(500);
            $scope.additionComplete = true;
            $scope.additionSuccess = response.data.status === 'Success';
            $scope.senderResult = response.data.msg;
            $scope.resetForm();
        }, function(response){
            loadMask.fadeOut(500);
        });
    };

    $scope.resetForm = function() {
        $.each($scope.params, function(k, v) {
            $scope.params[k] = '';
        });

        $scope.noStayLimitCheck();
        $scope.datePickVal = null;
        $scope.additionReportForm.$setPristine(true);
        $scope.additionReportForm.$setUntouched(true);

        // scroll to top of form instantly
        $('html, body').scrollTop($('body').offset().top);
    };

    $scope.noStayLimitCheck = function() {
        if ($scope.settings.no_stay_limit) {
            $scope.settings.old_time_stay_limit = $scope.params.time_stay_limit;
            $scope.params.time_stay_limit = 'No stay limit';
            return;
        }

        $scope.params.time_stay_limit = $scope.settings.old_time_stay_limit;
    };

    $scope.isFreeStopToggled = function() {
        if ($scope.settings.is_free_stop) {
            $scope.settings.old_fees = $scope.params.fees;
            $scope.params.fees = 'Free';
            return;
        }

        $scope.params.fees = $scope.settings.old_fees;
    };

    $scope.closeResultAlert = function() {
        $scope.additionComplete = false;
    };
});
angular.module('daysend').controller('addToDirectoryController', function($scope, $http){
    $scope.additionComplete = false;
    $scope.params = {};
    $scope.params['log_update'] = true;
    $scope.params['log_adds'] = true;

    $scope.onSubmit = function(e, additionForm) {
        if (!additionForm.$valid) {
            $('html').loadMask('The report is not valid, please ensure all required fields are completed before submitting.', false)
                .fadeOut(4000);
            return false;
        }

        var loadMask = $('html').loadMask();
        $scope.params.service = 'add_listing';

        $http({
            method: 'POST',
            url: 'service/DirectoryService.php',
            data: $scope.params
        }).then(function(response) {
            loadMask.fadeOut(200);
            $scope.additionComplete = true;
            $scope.additionSuccess = response.data.status === 'Success';
            $scope.resultData = response.data.msg;

            if ($scope.additionSuccess && typeof $scope.$close === 'function') {
                $scope.$close();
            }
        }, function(response){
            loadMask.fadeOut(500);
            $scope.additionComplete = true;
            $scope.additionSuccess = false;
            $scope.resultData = 'There was an issue making the addition. Please check your internet connection.';
        });
    };
});
angular.module('daysend').controller('adminExpiriesController', function($scope, $http){
    $scope.showUploadResult = false;
    $scope.uploadSuccess = true;

    $scope.closeUploadResult = function() {
        $scope.showUploadResult = false;
    };

    $scope.onSubmit = function(e) {
        var loadMask = $('html').loadMask();
        var params = {
            service: 'expire_accounts',
            csvUpload: $scope.file
        };

        $http({
            method: 'POST',
            url: 'service/RegistrationService.php',
            headers: {
                'Content-Type': undefined
            },
            data: params,
            transformRequest: function (data, headersGetter) {
                var formData = new FormData();
                angular.forEach(data, function (value, key) {
                    formData.append(key, value);
                });

                // Just for future reference.
                // var headers = headersGetter();
                // delete headers['Content-Type'];

                return formData;
            }
        }).then(function(response) {
            loadMask.fadeOut(500);
            $scope.uploadResult = response.data.msg;
            $scope.uploadSuccess = response.data.status === 'Success';
            $scope.showUploadResult = true;
        }, function(response){
            loadMask.fadeOut(500);
            $scope.uploadResult = "Unable to make the request, check your internet connection.";
            $scope.uploadSuccess = false;
            $scope.showUploadResult = true;
        });
    };

    $scope.onFileSelect = function (e) {
        var file = e.target.files[0];
        $scope.file = file ? file : undefined;
        $scope.$apply();
    };
});
angular.module('daysend').controller('adminInquiriesController', function($scope, $http){
    $scope.showUploadResult = false;
    $scope.uploadSuccess = true;

    $scope.closeUploadResult = function() {
        $scope.showUploadResult = false;
    };

    $scope.onSubmit = function(e) {
        var loadMask = $('html').loadMask();
        var params = {
            service: 'inquire_accounts',
            csvUpload: $scope.file
        };

        $http({
            method: 'POST',
            url: 'service/RegistrationService.php',
            headers: {
                'Content-Type': undefined
            },
            data: params,
            transformRequest: function (data, headersGetter) {
                var formData = new FormData();
                angular.forEach(data, function (value, key) {
                    formData.append(key, value);
                });

                // Just for future reference.
                // var headers = headersGetter();
                // delete headers['Content-Type'];

                return formData;
            }
        }).then(function(response) {
            loadMask.fadeOut(500);
            $scope.uploadResult = response.data.msg;
            $scope.uploadSuccess = response.data.status === 'Success';
            $scope.showUploadResult = true;
        }, function(response){
            loadMask.fadeOut(500);
            $scope.uploadResult = "Unable to make the request, check your internet connection.";
            $scope.uploadSuccess = false;
            $scope.showUploadResult = true;
        });
    };

    $scope.onFileSelect = function (e) {
        var file = e.target.files[0];
        $scope.file = file ? file : undefined;
        $scope.$apply();
    };
});
angular.module('daysend').controller('changeEmailController', function($scope, $http, $location){
    $scope.onSubmit = function(e) {
        var loadMask = $('html').loadMask();
        var params = $(e.target).serializeObject();
        params.service = 'change_email';

        $http({
            method: 'POST',
            url: 'service/RegistrationService.php',
            data: params
        }).then(function(response) {
            loadMask.fadeOut(500);
            loadMask = $('html').loadMask(response.data.msg, false);
            setTimeout(function() {
                loadMask.fadeOut(100, function() {
                    $(this).remove();
                });

                if(response.data.status === 'Success') {
                    $location.url("/");
                    location.reload();
                }
            }, 4000);
        }, function(response){
            loadMask.fadeOut(500);
        });
    }
});

angular.module('daysend').controller('changePasswordController', function($scope, $http, $location){
    $scope.onSubmit = function(e) {
        var loadMask = $('html').loadMask();
        var params = $(e.target).serializeObject();
        params.service = 'change_password';

        $http({
            method: 'POST',
            url: 'service/RegistrationService.php',
            data: params
        }).then(function(response) {
            loadMask.fadeOut(500);
            loadMask = $('html').loadMask(response.data.msg, false);
            setTimeout(function() {
                loadMask.fadeOut(100, function() {
                    $(this).remove();
                });

                if(response.data.status === 'Success') {
                    $location.url("/");
                }
            }, 4000);
        }, function(response){
            loadMask.fadeOut(500);
        });
    }
});

angular.module('daysend').controller('deleteListingController', function($scope, $http, $listingId, $onConfirm, $onCancel) {
    var me = $scope;
    $scope.recordInDeletes = false;
    $scope.recordInUpdates = true;
    $scope.beforeListing = '';
    $scope.afterListing = '';
    $scope.listingId = $listingId;
    $scope.onConfirm = $onConfirm;
    $scope.onCancel = $onCancel;

    $scope.ok = function() {
        $scope.deleteListing();
        $scope.onConfirm();
        $scope.$close();
    };

    $scope.cancel = function() {
        $scope.onCancel();
        $scope.$close();
    };

    $scope.deleteListing = function() {
        var loadMask = $('html').loadMask();
        var params = {};
        params.service = 'delete_listing';
        params.listing_id = $scope.listingId;
        params.log_in_updates = $scope.recordInUpdates;
        params.log_in_deletes = $scope.recordInDeletes;
        params.before_listing = $scope.beforeListing;
        params.after_listing = $scope.afterListing;

        $http({
            method: 'POST',
            url: 'service/DirectoryService.php',
            data: params
        }).then(function(response) {
            loadMask.fadeOut(500);

            loadMask = $('html').loadMask(response.data.msg, false);
            loadMask.fadeOut(2500);
        }, function(response){
            loadMask.fadeOut(500);
        });
    };
});
angular.module('daysend').controller('directorySearchController', function($scope, $http, $uibModal){
    $scope.directoryResults = [];
    $scope.resultMessage = 'Please enter a search term with no less than 7 characters.';
    $scope.searchUpdateResult = '';
    $scope.searchUpdateSuccess = true;
    $scope.params = {};

    $scope.onSubmit = function(e) {
        var loadMask = $('html').loadMask();
        // Get params from event target, if not specified use last params value.
        $scope.params = $.isEmpty(e) ? $scope.params : $(e.target).serializeObject();
        $scope.params.service = 'search_directory';

        $http({
            method: 'POST',
            url: 'service/DirectoryService.php',
            data: $scope.params
        }).then(function(response) {
            loadMask.fadeOut(500);
            $scope.showResultMessage = true;
            $scope.resultSuccess = response.data.status === 'Success';

            // Prevents issues with escaping
            $scope.directoryResults = response.data.results;
            $scope.resultMessage = response.data.msg;
            loadMask.fadeOut(500);
        }, function(response){
            loadMask.fadeOut(500);
        });
    };

    $scope.closeResultAlert = function() {
        $scope.showResultMessage = false;
    };

    $scope.showUpdateListingForm = function(e, listingId, listingDesc) {
        e.preventDefault();

        $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-update-directory',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'modules/app/templates/subscribed/update-report-form.php',
            scope: $scope,
            size: 'lg',
            resolve: {
                $listingID: function() { return listingId; },
                $listingDesc: function() { return listingDesc; }
            },
            controller: 'updateReportController'
        })
    };

    $scope.showEditListingForm = function(e, listingId, listingDesc) {
        e.preventDefault();
        var triggerRefresh = false;

        $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-edit-directory-listing',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'modules/app/templates/admin/edit-listing.php',
            size: 'lg',
            resolve: {
                $listingID: function() { return listingId; },
                $listingDesc: function() { return listingDesc; },
                onSave: function() {
                    return function() {
                        triggerRefresh = true;
                    }
                }
            },
            controller: 'editListingController'
        }).closed.then(function(){
            if (triggerRefresh) {
                // Reload search result to get updated list.
                $scope.onSubmit();
            }
        })
    };

    $scope.deleteListingConfirm = function(e, listingId) {
        e.preventDefault();
        $scope.deleteListingModal = {};
        var parentScope = $scope;
        var triggerRefresh = false;

        parentScope.deleteListingModal = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-confirm-delete-directory-listing',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'modules/app/templates/admin/delete-listing.php',
            controller: 'deleteListingController',
            size: 'md',
            scope: $scope,
            resolve: {
                $listingId: function() {
                    return listingId;
                },
                $onConfirm: function() {
                    return function() {
                        triggerRefresh = true;
                    };
                },
                $onCancel: function() {
                    return function() { };
                }
            }
        }).closed.then(function(){
            if (triggerRefresh) {
                // Reload search result to get updated list.
                $scope.onSubmit();
            }
        });
    };

    /**
     * @method toggleHelp
     * @description Whether to show or hide the Help modal.
     * @param {Boolean} enable
     */
    $scope.toggleHelp = function(enable) {
        if(enable) {
            $scope.helpModal = $uibModal.open({
                animation: true,
                templateUrl: 'modules/app/templates/subscribed/search-help.php',
                controller: 'helpPageController',
                scope: $scope,
                size: 'lg'
            });
        } else {
            $scope.helpModal.close();
        }
    };
});
angular.module('daysend').controller('directRegistrationController', function($scope, $http){
    $scope.additionComplete = false;
    $scope.params = {};

    $scope.onSubmit = function(e, regForm) {
        if (!regForm.$valid) {
            $('html').loadMask('The form is not valid, please ensure all required fields are completed before submitting.', false)
                .fadeOut(4000);
            return false;
        }

        var loadMask = $('html').loadMask();
        $scope.params.service = 'direct_registration';

        $http({
            method: 'POST',
            url: 'service/RegistrationService.php',
            data: $scope.params
        }).then(function(response) {
            loadMask.fadeOut(200);
            $scope.regComplete = true;
            $scope.regSuccess = response.data.status === 'Success';
            $scope.resultData = response.data.msg;
        }, function(response){
            loadMask.fadeOut(500);
            $scope.regComplete = true;
            $scope.regSuccess = false;
            $scope.resultData = 'There was an issue with this request. Please check your internet connection.';
        });
    };
});
angular.module('daysend').controller('editListingController', function($scope, $http, $listingID, $listingDesc, onSave){
    $scope.updateComplete = false;
    $scope.params = {};
    $scope.onSave = onSave;

    $scope.params.listing_id = $listingID;

    // First remove any existing linebreaks (for whatever reason they might exist). Then, replace br with single line breaks.
    var regex = /<br\s*[\/]?>/gi;
    $listingDesc = $listingDesc.replace(/(?:\r\n|\r|\n)/g, '');
    $scope.params.listing_content = $listingDesc.replace(regex, "\n");
    $scope.params.log_update = true;

    $scope.onSubmit = function(e, editListingForm) {
        if (!editListingForm.$valid) {
            $('html').loadMask('The form is not valid, please ensure all required fields are completed before submitting.', false)
                .fadeOut(4000);
            return false;
        }

        var loadMask = $('html').loadMask();
        $scope.params.service = 'edit_listing';

        $http({
            method: 'POST',
            url: 'service/DirectoryService.php',
            data: $scope.params
        }).then(function(response) {
            loadMask.fadeOut(500);
            $scope.updateComplete = true;
            $scope.updateSuccess = response.data.status === 'Success';
            $scope.senderResult = response.data.msg;

            loadMask = $('html').loadMask(response.data.msg, false);
            loadMask.fadeOut(2500);

            if (typeof $scope.onSave === 'function') {
                $scope.onSave();
            }

            if ($scope.updateSuccess) {
                $scope.$close();
            }
        }, function(response){
            loadMask.fadeOut(500);
        });
    };
});
angular.module('daysend').controller('filesController', function($scope){
    $scope.displayMethod = 'list';
});
angular.module('daysend').controller('forgotPasswordController', function($scope, $http){
    $scope.onSubmit = function(e) {
        var loadMask = $('html').loadMask();
        var params = $(e.target).serializeObject();
        params.service = 'forgot_password_request';

        $http({
            method: 'POST',
            url: 'service/RegistrationService.php',
            data: params
        }).then(function(response) {
            loadMask.fadeOut(300);
            loadMask = $('#forgotPasswordCt').loadMask(response.data.msg, false);

            if(response.data.status !== 'Success') {
                setTimeout(function() {
                    loadMask.fadeOut(100, function() {
                        $(this).remove();
                    });
                }, 6000);
            }
        }, function(response){
            loadMask.fadeOut(500);
        });
    }
});

// This is used when displaying a help page. Used in multiple locations.
angular.module('daysend').controller('helpPageController', function($scope, $anchorScroll) {
    /**
     * Intercept the Help section (or others) click event in order to use angular's scrollTo.
     * This is necessary because of Angular route using the hash tags in all URI.
     * @param $event
     */
    $scope.delegateAnchorScroll = function($event) {
        if($($event.target).prop("tagName").toLowerCase() !== 'a') {
            return;
        }

        var id = $($event.target).attr('href');

        if(id.charAt(0) !== '#') {
            return;
        }

        $event.preventDefault();
        $anchorScroll(id.substr(1));
    };
    // do nothing
});
angular.module('daysend').controller('mainAppController', function($rootScope, $scope, $http, $route, $location, $anchorScroll){
    $scope.showMainAlert = true;
    $scope.isLoggedIn = false;
    $scope.isAdmin = false;
    $scope.loggedInUser = '';
    $scope.login = {};
    $scope.login.username = '';
    $scope.login.password = '';
    $scope.login.remember = false;
    $scope.currentRoute = {};
    $scope.loginAttempts = 0;

    $rootScope.$on("$locationChangeStart", function(event, next, current) {
        $scope.navCollapsed = true;
        $('.loading-mask').remove();
    });

    $rootScope.$on('$routeChangeSuccess', function (e, current, pre) {
        $scope.currentRoute = current;
    });

    $scope.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    $scope.closeMainAlert = function() {
        $scope.showMainAlert = false;
        //TODO: add cookie that takes an md5 of the text of the alert template, that is if we decide to keep this main alert for "announcements".
    };

    $scope.attemptLogin = function() {
        var loadMask = $('html').loadMask();
        var params = {
            service: 'attempt_login',
            username: $scope.login.username,
            password: $scope.login.password
        };

        if($scope.login.remember){
            params.remember = true;
        }

        $http({
            method: 'POST',
            url: 'service/LoginService.php',
            data: params
        }).then(function(response) {
            $scope.onLogin(response, loadMask);
        }, function(response){
            loadMask.fadeOut(500);
        });
    };

    $scope.onLogin = function(response, loadMask){
        $scope.isLoggedIn = response.data.status == 0;
        $scope.login.password = '';

        if ($scope.isLoggedIn) {
            $scope.loggedInUser = response.data.username;
            $scope.isAdmin = response.data.admin;
            $scope.navCollapsed = true;
            $scope.userMenuOpen = false;
            $scope.login.username = '';

            // Check if on a certain public page (like ordering) and redirect if so
            if (!$.isEmpty($scope.currentRoute) &&
                ($scope.currentRoute.loadedTemplateUrl === 'modules/app/templates/public/order.html' ||
                $scope.currentRoute.loadedTemplateUrl === 'modules/app/templates/public/order_free3.html'))
            {
                $location.path('/');
            }

            loadMask.fadeOut(500);
        } else {
            loadMask.fadeOut(500);

            if ($scope.loginAttempts > 0) { // Don't show message on first attempt (check login on init)
                loadMask = $('html').loadMask(response.data.msg, false);

                setTimeout(function() {
                    loadMask.fadeOut(500);
                }, 3000);
            }
        }

        $scope.loginAttempts++;
    };

    $scope.logOutUser = function() {
        var loadMask = $('html').loadMask();
        var params = {
            service: 'logout_user'
        };

        if($scope.login.remember){
            params.remember = true;
        }

        $http({
            method: 'POST',
            url: 'service/LoginService.php',
            data: params
        }).then(function(response){
            $scope.isAdmin = false;
            $scope.isLoggedIn = false;
            loadMask.fadeOut(500);
            $scope.navCollapsed = true;
            $scope.userMenuOpen = false;
            $location.url("/");
        }, function(response){
            loadMask.fadeOut(500);
        });
    };

    $scope.toggleUserMenu = function() {
        $scope.userMenuOpen = !$scope.userMenuOpen;
    };

    /**
     * Intercept the FAQ section (or others) click event in order to use angular's scrollTo.
     * This is necessary because of Angular route using the hash tags in all URI.
     * @param $event
     */
    $scope.delegateAnchorScroll = function($event) {
        if($($event.target).prop("tagName").toLowerCase() !== 'a') {
            return;
        }

        var id = $($event.target).attr('href');

        if(id.charAt(0) !== '#') {
            return;
        }

        $event.preventDefault();
        $anchorScroll(id.substr(1));
    };

    $scope.init = function() {
        var loadMask = $('html').loadMask();
        // Check if they were logged in already from session or cookie
        $http({
            method: 'POST',
            url: 'service/LoginService.php',
            data: {
                service: 'get_login_status'
            }
        }).then(function(response) {
            $scope.onLogin(response, loadMask);
        }, function(response){
            loadMask.hide();
        });
    };

    $scope.init();
});
angular.module('daysend').controller('mainMapController', function($scope, $http, $uibModal) {
    $scope.mapConfig = {
	streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        fullscreenControl: false // Controlled by us
    };

    $scope.distance = {
        unit: 'mile',
        maxRange: 10
    };

    $scope.poiOrderBy = 'alpha';

    // Data will be retrieved when the map is created.
    $scope.dataSourcesPOI = [];
    // Based on the POI's "enabled" value, we will add it to this array with just the ID property.
    $scope.selectedSourcesPOI = [];
    $scope.listGenerated = false;
    $scope.listCollapsed = false;
    $scope.fullscreen = false;
    $scope.controlsVisible = true;
    $scope.startLocationOnly = false;
    $scope.showDirections = false;

    $scope.directionsService = new google.maps.DirectionsService;
    $scope.directionsDisplay = new google.maps.DirectionsRenderer({
        draggable: true
    });

    // Initialize map controls
    $scope.startField = $('#mapperControls').find('.startLocation input')[0];
    $scope.endField = $('#mapperControls').find('.endLocation input')[0];
    $scope.maxRangeField = $('#mapperControls').find('.maxDistance input.max-range-field')[0];
    $scope.getDirectionsCt = function() {
        return $('#directionsContainer')[0];
    };

    $scope.startSearchBox = new google.maps.places.Autocomplete($scope.startField);
    $scope.endSearchBox = new google.maps.places.Autocomplete($scope.endField);

    $scope.customMarkers = [];

    // Creates or re-creates our map with the current mapConfig object.
    $scope.createMap = function() {
        var mapCt = document.getElementById('mapContainer');

        $scope.map = new google.maps.Map(mapCt, $scope.mapConfig);
        $scope.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push($('#mapperControls')[0]);
        $scope.map.controls[google.maps.ControlPosition.RIGHT_TOP].push($('#mapComponents .top-right-controls')[0]);
        $scope.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push($('#mapComponents .helpButton')[0]);

        // There isn't a catchall "change" event for the multiselect dropdown.
        $scope.sourceChangeCallbacks = {
            onHide: $scope.onSourceBoxHide,
            onShow: function() {
                $scope.selectedSourcesClone = $.extend(true, {}, $scope.selectedSourcesPOI);
            }
        };

        $scope.directionsDisplay.addListener('directions_changed', $.Function.pass($scope.onChangeDirections, [null, $scope]));

        $(window).bind('resize', $.Function.pass($scope.onMapResize, [$scope, mapCt]));
        $scope.onMapResize($scope, mapCt);
        $scope.loadPointSources($scope.setDefaultPoint);

        // Bind listener for submitting point listing updates
        // bind event to content click
        $('body').on('click', 'div.infoWinContent', function(e) {
            var theTarget = $(e.target);

            if (!theTarget.hasClass('directory-listing-edit-link')) {
                return;
            }

            e.preventDefault();
            var theListing = theTarget.parents('.directory-listing');

            $scope.showUpdateListingForm(e, theListing.attr('data-listing-id'), theListing.children('.directory-listing-content').html());
        });
    };

    $scope.onConfirmSourceChange = function() {
        $scope.onMapFieldChange();
        $scope.confirmSourceModal.dismiss();
    };

    $scope.onCloseSourceChangeModel = function() {
        $scope.confirmSourceModal.dismiss();
    };

    $scope.warnedOfSourceChange = false;

    $scope.onSourceBoxHide = function() {
        // Compare indexes and id's in each array to determine a change.
        var changed = false;
        for(var i = 0; i < $scope.selectedSourcesClone.length; i++) {
            if($scope.selectedSourcesPOI[i] == undefined || $scope.selectedSourcesClone[i].id != $scope.selectedSourcesPOI[i].id) {
                changed = true;
                break;
            }
        }
        for(var i = 0; i < $scope.selectedSourcesPOI.length; i++) {
            if($scope.selectedSourcesClone[i] == undefined || $scope.selectedSourcesClone[i].id != $scope.selectedSourcesPOI[i].id) {
                changed = true;
                break;
            }
        }

        if(!changed) return;

        // Only require confirmation if more than one source is selected.
        if(!$scope.warnedOfSourceChange && $scope.selectedSourcesPOI.length > 1) {
            $scope.confirmSourceModal = $uibModal.open({
                animation: true,
                templateUrl: 'modules/app/templates/public/map-source-confirm.html',
                size: 'sm',
                scope: $scope,
                controller: "mapSourceConfirmController",
                resolve: {
                    onConfirm: function() {
                        return $scope.onConfirmSourceChange;
                    },
                    onClose: function() {
                        return $scope.onCloseSourceChangeModel;
                    }
                }
            });

            $scope.warnedOfSourceChange = true;
        } else {
            $scope.onConfirmSourceChange();
        }
    };

    /**
     * @method getSourceTableNames
     * @returns Array of Strings
     */
    $scope.getSourceTableNames = function() {
        var selectedArr = [];

        for(var d = 0; d < $scope.dataSourcesPOI.length; d++) {
            var dataObj = $scope.dataSourcesPOI[d];

            for(var s = 0; s < $scope.selectedSourcesPOI.length; s++) {
                var selectedObj = $scope.selectedSourcesPOI[s];

                if(selectedObj.id == dataObj.id) {
                    selectedArr.push(dataObj.table_name);
                    break;
                }
            }
        }

        return selectedArr;
    };

    $scope.loadPointSources = function(callback) {
        if(!$.isFunction(callback)) {
            callback = function(){};
        }

        var loadMask = $('body').loadMask();

        $http({
            method: 'POST',
            url: 'service/MapperService.php',
            data: {
                service: 'get_point_sources'
            }
        }).then(function(response){
            $scope.dataSourcesPOI = response.data.data.data;
            $scope.selectedSourcesPOI = response.data.data.selected;
            callback();
            loadMask.fadeOut(500);
        }, function(response){
            console.error(response);
            callback();
            loadMask.fadeOut(500);
        });
    };

    $scope.onMapResize = function($scope, mapCt) {
        var mapHeight = parseInt($(window).height()) * 0.94;
        var sideBarNavHeight = $('.mapSidebar .nav-tabs').height();
        $(mapCt).css('height', mapHeight + 'px');
        $('.mapSidebar .tab-content').css('height', (mapHeight - sideBarNavHeight) + 'px');
        $scope.centerOnMarkers($scope.customMarkers);
        google.maps.event.trigger($scope.map, 'resize');
    };

    $scope.onListToggle = function() {
        $scope.listCollapsed = !$scope.listCollapsed;
        // Angular delay in digest.
        setTimeout(function() {
            google.maps.event.trigger($scope.map, 'resize');
        }, 100);
    };

    $scope.onControlsToggle = function(show) {
        $scope.controlsVisible = $.isEmpty(show) ? !$scope.controlsVisible : show;
    };

    $scope.resetMap = function() {
        $($scope.startField).blur();
        $($scope.endField).blur();
        $($scope.maxRangeField).blur();

        setTimeout(function() {
            $($scope.startField).val('');
            $($scope.startField).focus();
            $($scope.endField).val('');
            $($scope.endField).focus();
            $($scope.maxRangeField).val('10');
            $($scope.maxRangeField).focus();

            $scope.startSearchBox.set('place', void(0));
            $scope.endSearchBox.set('place', void(0));

            $scope.startLocationOnly = false;
            $scope.listGenerated = false;
            $scope.$apply();

            $scope.clearMarkers();
            $scope.setDefaultPoint();
            $scope.centerOnMarkers($scope.customMarkers);
        }, 10);
    };

    /**
     * Adds a default point to a marker.
     */
    $scope.setDefaultPoint = function() {
        $scope.clearMarkers();

        // Add the default pointer
        $scope.addPOIMarkers({
            "DaysEndPOI": [{
                "id": "274",
                "title": "AZ, QUARTZSITE",
                "city": "QUARTZSITE",
                "state": "AZ",
                "tableName": "DaysEndPOI",
                "icon_path": "img/markers/daysendmarker.png",
                "position": {
                    "lat": 33.663998,
                    "lng": -114.229737
                }
            }]
        });
    };

    /**
     * @method onMapFieldChange
     * Toggled on the change event for start or end location.
     */
    $scope.onMapFieldChange = function(){
        var startLocation = $scope.startSearchBox.getPlace();
        var endLocation = $scope.endSearchBox.getPlace();

        if($scope.startLocationOnly && !$.isEmpty(endLocation)) {
            $scope.endSearchBox.set('place', void(0));
            $($scope.endField).val('');
            return; // prevent double fire
        }

        // start & end locations need to both be set.
        if(!$.isEmpty(endLocation) && !$.isEmpty(startLocation) && !$scope.startLocationOnly) {
            $scope.setBasicDirections();
        } else if(!$.isEmpty(startLocation) && $scope.startLocationOnly){
            $scope.getSinglePointPOI();
        }
    };

    $scope.onListMarkerClick = function(marker) {
        $scope.centerOnMarkers([marker]);
        marker.showInfoWindow();
    };

    $scope.startSearchBox.addListener('place_changed', $scope.onMapFieldChange);
    $scope.endSearchBox.addListener('place_changed', $scope.onMapFieldChange);

    // We are using a debounce config to track this change event.
    $scope.$watch('distance.maxRange', $scope.onMapFieldChange);

    /**
     * @method setBasicDirections
     * This method takes the start & end locations, then queries Google for route data. This route data is then analyzed for vertices, in which we get relevant POI and plot.
     */
    $scope.setBasicDirections = function() {
        var startLocation = $scope.startSearchBox.getPlace();
        var endLocation = $scope.endSearchBox.getPlace();

        $scope.directionsService.route({
            origin: startLocation.formatted_address,
            destination: endLocation.formatted_address,
            travelMode: google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: true
        }, function(response, status) {
            if(status === google.maps.DirectionsStatus.OK) {
                $scope.startLocationOnly = false;
                $scope.showDirections = true;
                $scope.$apply();

                $scope.directionsDisplay.setDirections(response);
                $scope.directionsDisplay.setPanel($scope.getDirectionsCt());
                $scope.directionsDisplay.setMap($scope.map);
            } else {
                // TODO add in a true alerts library/directive for Angular or something else.
                window.alert('Unable to map directions.');
            }
        });
    };

    $scope.getSinglePointPOI = function() {
        // First, clear all existing markers.
        if(!$.isEmpty($scope.customMarkers)) {
            $scope.clearMarkers();
        }

        $scope.POIMarkers = [];

        var startLocation = $scope.startSearchBox.getPlace();
        var routePolys = [startLocation.geometry.location];

        // Set Start Location Marker
        var startMarker = new google.maps.Marker({
            title: 'Start Location',
            city: startLocation.address_components[0].short_name,
            state: startLocation.address_components[2].short_name,
            position: startLocation.geometry.location,
            map: $scope.map
        });

        $scope.customMarkers.push(startMarker);

        $scope.getValidPOI(routePolys);
    };

    /**
     * @method clearMarkers
     * Removes all existing markers from the map.
     */
    $scope.clearMarkers = function() {
        if(!$.isEmpty($scope.directionsDisplay)) {
            $scope.directionsDisplay.setMap(null);
        }

        if(!$.isEmpty($scope.customMarkers)) {
            for(var i = 0; i < $scope.customMarkers.length; i++) {
                $scope.customMarkers[i].setMap(null);
            }
        }

        $scope.customMarkers = [];
    };

    /**
     * @method getValidPOI
     * @description Makes a call to the server to retrieve POI that fall within a certain radius to any vertices on a route.
     * @param {Array} routePolys
     * @returns {Array} A set of Google map points configs.
     * I.E. (with minimum info provided)
     *    [{
     *      title: 'Point of Interest',
     *      position: {
     *       lat: -25.363,
     *       lng: 131.044
     *      },
     *      icon: '/path/to/icon'
     *    }]
     */
    $scope.getValidPOI = function(routePolys) {
        var routeVertices = [];

        for(var i = 0; i < routePolys.length; i++) {
            routeVertices.push({
                lat: routePolys[i].lat(),
                lng: routePolys[i].lng()
            });
        }

        var loadMask = $('body').loadMask();

        $http({
            method: 'POST',
            url: 'service/MapperService.php',
            timeout: 300000,
            data: {
                service: 'get_valid_poi',
                vertices: JSON.stringify(routeVertices),
                range: $scope.distance.maxRange,
                useMetric: ($scope.distance.unit == 'km'),
                tables: JSON.stringify($scope.getSourceTableNames())
            }
        }).then(function(response){
            $scope.addPOIMarkers(response.data.data);

            // Google maps does not provide an event for picking an alternate route.
            // Also, this is added here (not when we set directions) because the DOM isn't generated until after adding POI markers.
            $('#directionsContainer').find('div.adp-list tr:not(:first)').off('click').on('click', function() {
                // Take it outside this function's order of operations and let existing binds work.
                var routeIndex = parseInt($(this).children('td:first').attr('data-route-index'));
                $scope.onChangeDirections(routeIndex, $scope);
            });

            loadMask.fadeOut(500);
        }, function(response){
            console.error(response);
            loadMask.fadeOut(500);
        });
    };

    /**
     * Centers the passed in markers in the map viewport, and the start & end location.
     * This method is always deferred 50ms to provide Google time to process map size after points are added and other operations.
     * @param {Array} markers
     */
    $scope.centerOnMarkers = function(markers) {
        google.maps.event.trigger($scope.map, 'resize');

        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < markers.length; i++) {
            bounds.extend(markers[i].getPosition());
        }

        var startLocation = $scope.startSearchBox.getPlace();
        var endLocation = $scope.endSearchBox.getPlace();

        if(!$.isEmpty(startLocation)) {
            bounds.extend(startLocation.geometry.location);
        }

        if(!$.isEmpty(endLocation)) {
            bounds.extend(endLocation.geometry.location);
        }

        $scope.map.fitBounds(bounds);

        // Ensure zoom is set to at least 15 to show other nearby things (seems to default to 22 which is very zoomed in).
        var cZoom = $scope.map.getZoom();
        if (cZoom > 15) {
            $scope.map.setZoom(15);
        }
    };

    /**
     * @method addPOIMarkers
     * @description Takes an array of POI points and adds them to the map on a recursive delay.
     *  These markers are setup to tie into a system where we allow adding a title, description & note about the change.
     *  In order to do this, we have to setup info windows on click.
     * @param {google.maps.Marker} pointData
     */
    $scope.addPOIMarkers = function(pointData) {
        var markerConfigs = [];
        for(var tableName in pointData) {
            var points = pointData[tableName];

            for(var i = 0; i < points.length; i++) {
                var point = points[i];
                var priority = parseInt(point.priority);

                var newMarker = new google.maps.Marker({
                    title: point.title,
                    position: new google.maps.LatLng(point.position.lat, point.position.lng),
                    city: point.city,
                    state: point.state,
                    map: $scope.map,
                    tableName: tableName,
                    zIndexProcess: priority,
                    icon: new google.maps.MarkerImage(
                        point.icon_path,
                        null,
                        null,
                        null,
                        new google.maps.Size(26, 42)
                    )
                });

                markerConfigs.push(newMarker);

                var infoWin = new google.maps.InfoWindow({
                    height: '80%'
                });

                infoWin.addListener('closeclick', function() {
                    if(tableName === "DaysEndPOI") {
                        $('#mapperControls').fadeIn(600);
                    }
                });

                // Add a property so we can call it later on for this marker instead of requiring a click event.
                newMarker.showInfoWindow = $.Function.pass(function(infoWin, point, newMarker, $scope) {
                    if(tableName === "DaysEndPOI") {
                        $('#mapperControls').fadeOut(400, function() {
                            $scope.loadInfoWin(infoWin, point, newMarker);
                        });
                        return;
                    }

                    $scope.loadInfoWin(infoWin, point, newMarker);
                }, [infoWin, point, newMarker, $scope]);

                newMarker.addListener('click', newMarker.showInfoWindow);

                $scope.customMarkers.push(newMarker);
            }

            if(tableName === "DaysEndPOI") {
                $scope.listGenerated = true;
            }
        }

        $scope.onMapResize($scope, $('#mapContainer'));
        $scope.centerOnMarkers($scope.customMarkers);
    };

    // Get new POI for revised route
    $scope.onChangeDirections = function(option, $scope) {
        var trueOption;

        if(typeof(option) != "number"){
            trueOption = 0;
        } else {
            trueOption = option;
        }

        var routePolys = google.maps.geometry.encoding.decodePath($scope.directionsDisplay.getDirections().routes[trueOption].overview_polyline);

        // Clear and set directions to map
        if(!$.isEmpty($scope.customMarkers)) {
            $scope.clearMarkers();
        }

        $scope.POIMarkers = [];
        $scope.directionsDisplay.setPanel($scope.getDirectionsCt());
        $scope.directionsDisplay.setMap($scope.map);

        // Set POI Markers
        $scope.getValidPOI(routePolys);
    };

    /**
     * @method createInfoWinContent
     * @description Takes the extra information about a point and provides additional content & information.
     *  It adds the ability to submit a change to a POI's title, description & provide notes about the change.
     * @param point
     * @param marker
     */
    $scope.createInfoWinContent = function(point, marker) {
        var htmlContent =
            '<div class="infoWinForPOI" data-poi-id="{3}" data-poi-lat="{4}" data-poi-lng="{5}">' +
            '<h4>{1}</h4>' +
            '<div class="infoWinContent">{2}</div>' +
            '</div>';

        return $.String.format(htmlContent, point.title, point.info, point.id, point.position.lat, point.position.lng);
    };

    $scope.loadInfoWin = function(infoWin, point, marker) {
        // Only query directory info for day's end points.
        if(marker.tableName !== "DaysEndPOI") {
            point.info = "";
            infoWin.setContent($scope.createInfoWinContent(point, marker));
            infoWin.open($scope.map, marker);
            return;
        }

        var loadMask = $('body').loadMask();

        $http({
            method: 'POST',
            url: 'service/MapperService.php',
            data: {
                service: 'get_point_directory_info',
                city: marker.city,
                state: marker.state
            }
        }).then(function(response){
            var smallViewport = $(window).height() < 750;
            point.info = response.data.data;

            if (smallViewport) {
                $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-point-listings',
                    ariaDescribedBy: 'modal-body',
                    template: '<div class="mapperPointListingInfo" style="padding: 10px;">' +
                        '<i class="fa fa-lg fa-close modal-close-button" ng-click="$close()"></i>' + $scope.createInfoWinContent(point, marker) +
                        '<div class="row text-center"><button ng-click="$close()">Close</button></div>' +
                        '</div>',
                    size: 'lg'
                })
                .closed.then(function(){
                    $('#mapperControls').fadeIn();
                });
            } else {
                infoWin.setContent($scope.createInfoWinContent(point, marker));
                infoWin.open($scope.map, marker);
            }

            loadMask.fadeOut(500);
        }, function(response){
            console.error(response);
            loadMask.fadeOut(500);
        });
    };

    /**
     * @method toggleHelp
     * @description Whether to show or hide the Help modal.
     * @param {Boolean} enable
     */
    $scope.toggleHelp = function(enable) {
        if(enable) {
            $scope.helpModal = $uibModal.open({
                animation: true,
                templateUrl: 'modules/app/templates/public/map-help.html',
                controller: 'helpPageController',
                scope: $scope,
                size: 'lg'
            });
        } else {
            $scope.helpModal.close();
        }
    };

    /**
     * @method toggleMapFullScreen
     * @description The google maps full screen function doesn't work correctly so use our own styling toggle
     * @param enable
     */
    $scope.toggleMapFullScreen = function(enable) {
        if(enable) {
            $('#mapContainer .gm-style').parent().css('position', 'fixed');
            $('body').css({ overflow: 'hidden' });
            $scope.fullscreen = true;
        } else {
            $('#mapContainer .gm-style').parent().css('position', 'absolute');
            $('body').css({ overflow: '' });
            $scope.fullscreen = false;
        }

        google.maps.event.trigger($scope.map, 'resize');
    };

    $scope.showUpdateListingForm = function(e, listingId, listingDesc) {
        e.preventDefault();

        $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-update-directory',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'modules/app/templates/subscribed/update-report-form.php',
            size: 'lg',
            resolve: {
                $listingID: function() { return listingId; },
                $listingDesc: function() { return listingDesc; }
            },
            controller: 'updateReportController'
        })
    };

    // INITIALIZE MAP
    // delay is for tab switching
    setTimeout(function() {
        $scope.createMap();
        $.setResizableColumns($('#mapOuterContainer').find('> div.row'), $('#mapComponents'), $('#mapSidebar'), $('#mapSidebar').find('.resizeBar'), $.Function.pass($scope.onMapResize, [$scope, $('#mapContainer')]));
    }, 300);
});

angular.module('daysend').directive('daysendMapper', function() {
    return {
        scope: false,
        controller: 'mainMapController',
        templateUrl: 'modules/app/templates/public/map.html'
    }
});

angular.module('daysend').controller('mapSourceConfirmController', function($scope, $uibModal, onConfirm, onClose) {
    $scope.onConfirm = onConfirm;
    $scope.onClose = onClose;
});
angular.module('daysend').controller('orderPageController', function($scope, $http, $promoType){
    $scope.orderCompleted = false;
    $scope.orderError = false;

    if ($promoType === 'free3') {
        $scope.showPasswordField = true;
    }

    $scope.onSubmit = function(e, orderForm) {
        if (!orderForm.$valid) {
            $('html').loadMask('The order form is not valid, please ensure all required fields are completed before submitting.', false)
                .fadeOut(4000);
            return false;
        }

        var loadMask = $('html').loadMask();
        // Not best practice but since we're doing error checking on the php side we don't really need models to track each specific field.
        // This may be revised if that is to be the case.
        var params = $(e.target).serializeObject();
        params.service = 'submit_order';
        params.promo_type = $.isEmpty($promoType) ? '' : $promoType;

        $http({
            method: 'POST',
            url: 'service/RegistrationService.php',
            data: params
        }).then(function(response) {
            loadMask.fadeOut(500);
            if(response.data.status === 'Success') {
                $scope.orderError = false;
                $scope.orderConfirmationText = response.data.msg;
                $scope.orderedOnlineAccess = params.delivery === 'DaysEnd site';
                $scope.regEmail = params.email;

                if ($scope.orderedOnlineAccess) {
                    switch (params.payment) {
                        case 'PayPal':
                            $scope.orderConfPage = 'modules/app/templates/public/registered-paypal.html';
                            break;
                        case 'By Mail':
                            $scope.orderConfPage = 'modules/app/templates/public/registered-mail.html';
                            break;
                        case 'Wallet':
                            $scope.orderConfPage = 'modules/app/templates/public/registered-google-wallet.html';
                            break;
                        default:
                            break;
                    }
                } else if ($promoType === 'free3') {
                    $scope.orderConfPage = 'modules/app/templates/public/registered-free3promo.html';
                } else { // Implies ordering a CD
                    $scope.orderConfPage = 'modules/app/templates/public/ordered-cd.html';
                }

                $scope.orderCompleted = true;
            } else {
                $scope.orderError = true;
                $scope.orderErrorText = response.data.msg;
            }
        }, function(response){
            $scope.orderError = true;
            $scope.orderErrorText = "An issue occurred making a request to the server. Please check your internet connection.";
            loadMask.fadeOut(500);
        });
    };

    $scope.closeOrderError = function() {
        $scope.orderError = false;
    };
});
angular.module('daysend').controller('photosController', function($scope, $http, $uibModal, $anchorScroll){
    var parentScope = $scope;
    $scope.photoListView = true;

    $scope.enlargePhoto = function($event) {
        $event.preventDefault();

        $scope.imgSrc = $($event.delegateTarget).attr('data-src');
        $scope.title = $($event.delegateTarget).attr('title');
        var modal;

        modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-photo',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'modules/app/templates/public/photo-view.html',
            size: 'lg',
            resolve: {
                imgSrc: function() { return parentScope.imgSrc; },
                title: function() { return parentScope.title; },
            },
            controller: function($scope) {
                $scope.showModalFooter = true;
                $scope.imgSrc = parentScope.imgSrc;
                $scope.title = parentScope.title;
                $scope.cancel = function() {
                    modal.dismiss('cancel');
                };
            }
        })
    };

    $scope.selectListPhoto = function($event) {
        $scope.imgSrc = $($event.delegateTarget).attr('data-src');
        $scope.title = $($event.delegateTarget).attr('title');

        // Give the image a moment to calculate dimensions so it scrolls to it properly
        setTimeout(function() {
            $anchorScroll("photo-view");
        }, 200);
    };

    $scope.onSwitchToThumbs = function() {
        var parentScope = $scope;

        var modal = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-confirm-thumbnail-load',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'modules/app/templates/public/photo-thumb-confirm.html',
            size: 'md',
            controller: function($scope) {
                $scope.onConfirm = function() {
                    parentScope.photoListView = false;
                    modal.dismiss('cancel');
                };

                $scope.onClose = function() {
                    modal.dismiss('cancel');
                };
            }
        });
    };
});
angular.module('daysend').controller('questionPageController', function($scope, $http, $location){
    $scope.onSubmit = function(e) {
        var loadMask = $('html').loadMask();
        var params = $(e.target).serializeObject();
        params.service = 'ask_question';

        $http({
            method: 'POST',
            url: 'service/RegistrationService.php',
            data: params
        }).then(function(response) {
            loadMask.fadeOut(500);
            loadMask = $('html').loadMask(response.data.msg, false);
            setTimeout(function() {
                loadMask.fadeOut(100, function() {
                    $(this).remove();
                });

                if(response.data.status === 'Success') {
                    if (params.delivery === 'DaysEnd site') {
                        $location.url("#register");
                    } else {
                        $location.url("/");
                    }
                }
            }, 4000);
        }, function(response){
            loadMask.fadeOut(500);
        });
    }
});
angular.module('daysend').controller('sendMailController', function($scope, $http){
    $scope.onSubmit = function(e, form) {
        if (!form.$valid) {
            $('html').loadMask('The form is not valid, please ensure all required fields are completed before submitting.', false)
                .fadeOut(4000);
            return false;
        }

        var loadMask = $('html').loadMask();
        $scope.showResultData = false;
        var params = $(e.target).serializeObject();
        params.service = 'send_mail';

        $http({
            method: 'POST',
            url: 'service/MailService.php',
            data: params
        }).then(function(response) {
            loadMask.fadeOut(500);
            $scope.showResultData = true;
            $scope.resultData = response.data.msg;

            setTimeout(function() {
                $scope.showResultData(false);
            }, 8000);
        }, function(response){
            loadMask.fadeOut(500);
        });
    };

    $scope.onInit = function() {
        if (typeof $('textarea.textEditor').summernote === 'function') {
            $('textarea.textEditor').summernote({
                height: 300
            });
        }
    };
});
angular.module('daysend').controller('updatesLogController', function($scope, $http){
    $scope.sortType = 'date';
    $scope.sortReverse = true;
    $scope.dataSource = 'current';
    $scope.searchUpdate = '';
    $scope.params = {};

    // Initial data needed for change detection services
    $scope.updates = typeof initialUpdatesData === 'undefined' || $.isEmpty(initialUpdatesData) ? [] : initialUpdatesData.data;

    $scope.changeDataSource = function() {
        var loadMask = $('html').loadMask();
        $scope.params.service = 'get_updates';
        $scope.params.data_source = $scope.dataSource;

        $http({
            method: 'POST',
            url: 'service/DirectoryService.php',
            data: $scope.params
        }).then(function(response) {
            loadMask.fadeOut(500);

            if (response.data.status !== 'Success') {
                loadMask = $('html').loadMask(response.data.msg, false);
                setTimeout(function() {
                    loadMask.fadeOut(500);
                }, 5000);

                return;
            }

            $scope.updates = response.data.data;

        }, function(response){
            loadMask.fadeOut(500);
        });
    };
});
angular.module('daysend').controller('updateReportController', function($scope, $http, $listingID, $listingDesc, $filter){
    $scope.additionComplete = false;
    $scope.settings = {};
    $scope.params = {};

    // Incase this was passed into the controller
    $scope.singleListingUpdate = !$.isEmpty($listingID) && !$.isEmpty($listingDesc);
    $scope.params.listing_id = !$scope.singleListingUpdate ? '' : $listingID;
    $scope.params.current_listing_desc = !$scope.singleListingUpdate ? '' : $listingDesc;
    $scope.params.listing_is_accurate = false;

    $scope.showDatePick = false;
    $scope.datePickVal = null;
    $scope.datePickOptions = {
        datepickerMode: 'month',
        minMode: 'month',
        maxMode: 'month',
        showWeeks: true,
        formatMonth: 'MMM',
        formatYear: 'yy',
        formatDay: ''
    };

    $scope.onDateChange = function() {
        $scope.params['month_year_visited'] = $filter('date')($scope.datePickVal, 'MMM yy');
    };

    $scope.toggleDatePick = function() {
        $scope.showDatePick = !$scope.showDatePick;
    };

    $scope.onSubmit = function(e, updateReportForm) {
        if (!updateReportForm.$valid) {
            $('html').loadMask('The report is not valid, please ensure all required fields are completed before submitting.', false)
                .fadeOut(4000);
            return false;
        }

        var loadMask = $('html').loadMask();
        $scope.params.service = 'update_report';

        $http({
            method: 'POST',
            url: 'service/DirectoryService.php',
            data: $scope.params
        }).then(function(response) {
            loadMask.fadeOut(500);
            $scope.updateComplete = true;
            $scope.updateSuccess = response.data.status === 'Success';
            $scope.senderResult = response.data.msg;
            $scope.resetForm();

            // When using specific listing, this page is not intended to be reusable, so show result in mask popup.
            if ($scope.singleListingUpdate) {
                $scope.$parent.resultMessage = $scope.senderResult;
                $scope.$parent.resultSuccess = $scope.updateSuccess;
                $scope.$close();
                $('html, body').scrollTop($('body').offset().top);
            }
        }, function(response){
            loadMask.fadeOut(500);
        });
    };

    $scope.resetForm = function() {
        $.each($scope.params, function(k, v) {
            $scope.params[k] = '';
        });

        $scope.datePickVal = null;
        $scope.updateReportForm.$setPristine(true);
        $scope.updateReportForm.$setUntouched(true);

        // scroll to top of form instantly
        $('html, body').scrollTop($('body').offset().top);
    };

    $scope.closeResultAlert = function() {
        $scope.updateComplete = false;
    };
});